import $$ from 'dom7';
import Framework7 from 'framework7/framework7.esm.bundle.js';
import Framework7Keypad from 'framework7-plugin-keypad';

// Import F7 Styles
import 'framework7/css/framework7.bundle.css';

// Import Icons and App Custom Styles
import '../css/icons.css';
import '../css/app.css';
import '../css/components.css';
import '../css/framework7.keypad.min.css';
import '../css/font-awesome.min.css';
import '../css/select2.css';
import '../css/nice-select.css';
import 'video.js/dist/video-js.min.css';
import 'videojs-record/dist/css/videojs.record.css';
import 'videojs-record/dist/css/videojs.record.css';
import 'jquery.button-audio-player/dist/jquery.button-audio-player.css';
// Import Cordova APIs
import cordovaApp from './cordova-app.js';
// Import Routes
import routes from './routes.js';

Framework7.use(Framework7Keypad);

var app = new Framework7({
  root: '#app', // App root element
  id: 'app.nexcredloan.com', // App bundle ID
  name: 'NexCred', // App name
  theme: 'auto', // Automatic theme detection
  sheet: {
    backdrop: true,
    closeByBackdropClick: true,
  },
  view: {
    pushState: true,
    pushStateSeparator: '#!',
    pushStateOnLoad: true,
    preloadPreviousPage: false,
  },
  smartSelect: {
    routableModals: false,
    closeOnSelect: true,
  },
  // App root data
  data: function () {
    return {
      user: {
        firstName: 'John',
        lastName: 'Doe',
      },

    };
  },
  // App root methods
  methods: {
    initImageUpload: function(box) {
      let uploadField = box.querySelector('.image-upload');
        
        uploadField.addEventListener('change', getFile);
      
        function getFile(e){
          let file = e.currentTarget.files[0];
          checkType(file);
        }
      
        function checkType(file){
          let imageType = /image.*/;
          if (!file.type.match(imageType)) {
            app.dialog.alert('This is not an Image');
            throw 'This is not an image';
          } else if (!file){
            app.dialog.alert('No file found');
            throw 'No file found';
          } else {
            var thumb = box.querySelector('.upload-options');
            var lbl = box.querySelector('.lblIMG');
            thumb.style.background = '#62A845';
            lbl.style.color = '#FFF';
            lbl.textContent = '\u2713 File Uploaded Successfully';
          }
        }
    },
    request: function( url, data, datatype, beforesend, success, error) {
      $.ajax({
        url: url,
        data: data,
        cache: false,
        dataType: datatype,
        type: 'POST',
        crossDomain : true,
        beforeSend: function() {
          beforesend();
        },
        success: function(_response){
          success(_response);
        },
        error: function(_error){
          error(_error);
        }
      });
    },
    post: function( url, data, datatype, beforesend, success, error) {
      $.ajax({
        url: url,
        data: data,
        cache: false,
        dataType: datatype,
        type: 'POST',
        mimeType: "multipart/form-data",
        contentType: false,
        processData: false,
        crossDomain : true,
        beforeSend: function() {
          beforesend();
        },
        success: function(_response){
          success(_response);
        },
        error: function(_error){
          error(_error);
        }
      });
    },
    getUserData: function(success, error) {
      $.ajax({
        url: 'https://nexcredloan.com/app/php/auth.php',
        data: {userInfo: true, token: localStorage.getItem('authToken'), uuid: localStorage.getItem('uuid')},
        cache: false,
        dataType: 'json',
        type: 'POST',
        crossDomain : true,
        beforeSend: function() {
          $$('.reload-icon').addClass('rotate');
        },
        success: function(_response){
          $$('.reload-icon').removeClass('rotate');
          success(_response);
        },
        error: function(_error){
          $$('.reload-icon').removeClass('rotate');
        }
      });
    },
    getProfile: function(success, error) {
      $.ajax({
        url: 'https://nexcredloan.com/app/php/getProfile.php',
        data: {token: localStorage.getItem('authToken')},
        cache: false,
        dataType: 'json',
        type: 'POST',
        crossDomain : true,
        beforeSend: function() {
        },
        success: function(_response){
          success(_response);
        },
        error: function(_error){
        }
      });
    },
    getBank: function(success, error) {
      $.ajax({
        url: 'https://nexcredloan.com/app/php/auth.php',
        data: {bankDetails: true},
        cache: false,
        dataType: 'json',
        type: 'POST',
        crossDomain : true,
        beforeSend: function() {},
        success: function(_response){
          success(_response);
        },
        error: function(_error){
          error(_error);
        }
      });
    },
    getPIN: function(pincode, success, error) {
      var settings = {
        "async": true,
        "crossDomain": true,
        "url": "https://pincode.p.rapidapi.com/",
        "method": "POST",
        "headers": {
          "x-rapidapi-host": "pincode.p.rapidapi.com",
          "x-rapidapi-key": "01337a4178msh4d623cd6e1ee09dp1593f0jsn7b7701045cb2",
          "content-type": "application/json",
          "accept": "application/json"
        },
        "processData": false,
        "data": "{\"searchBy\":\"pincode\",\"value\":"+pincode+"}"
      }
      
      $.ajax(settings).done(function (response) {
        if(response.length === 0) {
          error("no places found");
        }
        else {
          success(response);
        }
      });
    },
    getPINnew: function(pincode, success, error) {
      const settings = {
        "async": true,
        "crossDomain": true,
        "url": "https://pincode13.p.rapidapi.com/"+pincode,
        "method": "GET",
        "headers": {
          "X-RapidAPI-Key": "9fa205ddacmsh524d6a0bb8ea16ep1e09b6jsn41b05454f309",
          "X-RapidAPI-Host": "pincode13.p.rapidapi.com"
        }
      };
      
      $.ajax(settings).done(function (response) {
        if(response.length === 0) {
          error("no places found");
        }
        else {
          success(response);
        }
      });
    },
    saveAgreement: function(success, error) {
      var data = $$('#agreement-popup .page-content .block').html();
      $.ajax({
        url: 'https://nexcredloan.com/app/php/saveAgreement.php',
        data: {token: localStorage.getItem('authToken')},
        cache: false,
        dataType: 'json',
        type: 'POST',
        crossDomain : true,
        success: function(_response){
          success(_response);
        },
        error: function(_error){
          error(_error);
        }
      });
    },
    getSettings: function(success, error) {
      $.ajax({
        url: 'https://nexcredloan.com/app/php/auth.php',
        data: {getSettings: true},
        cache: false,
        dataType: 'json',
        type: 'POST',
        crossDomain : true,
        beforeSend: function() {},
        success: function(_response){
          success(_response);
        },
        error: function(_error){
          error(_error);
        }
      });
    },
    addComma: function(x) {
      var parts = x.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return parts.join(".");
    },
    getmaxLoan: function(income) {
      if(income <= 15000) {
        return 100000;
      }
      else if(income > 15000 && income <= 30000) {
        return 200000;
      }
      else if(income > 30000 && income <= 50000) {
        return 300000;
      }
      else if(income > 50000 && income <= 75000) {
        return 400000;
      }
      else if(income > 75000) {
        return 500000;
      }
    },
  },
  // App routes
  routes: routes,



  // Input settings
  input: {
    scrollIntoViewOnFocus: Framework7.device.cordova && !Framework7.device.electron,
    scrollIntoViewCentered: Framework7.device.cordova && !Framework7.device.electron,
  },
  // Cordova Statusbar settings
  statusbar: {
    overlay: Framework7.device.cordova && Framework7.device.ios || 'auto',
    iosOverlaysWebView: true,
    androidOverlaysWebView: false,
  },
  on: {
    init: function () {
      var f7 = this;
      if (f7.device.cordova) {
        // Init cordova APIs (see cordova-app.js)
        //push notifications
        document.addEventListener('deviceready', OneSignalInit, false);
        function OneSignalInit() {
          // Uncomment to set OneSignal device logging to VERBOSE  
          // window.plugins.OneSignal.setLogLevel(6, 0);
          
          // NOTE: Update the setAppId value below with your OneSignal AppId.
          window.plugins.OneSignal.setAppId("67e830fd-4100-428b-b47f-248b259c961d");
          window.plugins.OneSignal.setNotificationOpenedHandler(function(jsonData) {
            console.log('notificationOpenedCallback: ' + JSON.stringify(jsonData));
          });
          //Prompts the user for notification permissions.
            //    * Since this shows a generic native prompt, we recommend instead using an In-App Message to prompt for notification permission (See step 6) to better communicate to your users what notifications they will get.
            window.plugins.OneSignal.promptForPushNotificationsWithUserResponse(function(accepted) {
              console.log("User accepted notifications: " + accepted);
          });
          var deviceID = device.uuid;
          window.plugins.OneSignal.setExternalUserId(deviceID);
          localStorage.setItem('uuid', deviceID);
          //console.log(deviceID);
        }
        cordovaApp.init(f7);
      }
      var timer = setInterval(() => {
        if(f7.initialized) {
          if(localStorage.getItem('page')) {
            var pageName = localStorage.getItem('page');
            f7.views.main.router.navigate({name: pageName});
          }
          clearInterval(timer);
        }  
      }, 50);
           

      var numpadInline = f7.keypad.create({
        inputEl: '#demo-numpad-inline',
        containerEl: '#numpad-inline-container',
        toolbar: false,
        valueMaxLength: 4,
        dotButton: false,
        formatValue: function (value) {
          value = value.toString();
          return ('****').substring(0, value.length) + ('____').substring(0, 4 - value.length);
        },
        on: {
          change(keypad, value) {
            value = value.toString();
            if (value.length === 4) {
              //check otp
              f7.methods.request('https://nexcredloan.com/app/php/auth.php',
              {OTPver: true, mobile: localStorage.getItem('phone'), OTP: value},
              'json',
              function() {
                app.preloader.show();
              },
              function(data) {
                app.preloader.hide();
                if(data.status == 'success') {
                  if(localStorage.getItem('mode') == 'login'){
                    f7.methods.getUserData(
                      function(user) {
                        if(user.loanData.applied) {
                          localStorage.setItem('maxLoan', '100000');
                          if(user.loanData.approve) {
                            f7.dialog.alert(data.msg, function () {
                              f7.views.main.router.navigate({name: 'wallet'});
                              f7.popup.close();
                            });
                          }
                          else {
                            f7.dialog.alert(data.msg, function () {
                              f7.views.main.router.navigate({name: 'approval'});
                              f7.popup.close();
                            });
                          }
                        }
                        else {
                          if(user.userData.income) {
                            f7.dialog.alert(data.msg, function () {
                              localStorage.setItem('maxLoan', '100000');
                              f7.views.main.router.navigate({name: 'home'});
                              f7.popup.close();
                            });
                          }
                          else if(user.userData.name) {
                            f7.dialog.alert(data.msg, function () {
                              f7.views.main.router.navigate({name: 'form3'});
                              f7.popup.close();
                            });
                          }
                          else {
                            f7.dialog.alert(data.msg, function () {
                              f7.views.main.router.navigate({name: 'form1'});
                              f7.popup.close();
                            });
                          }
                        }
                      }
                    );
                  }
                  else {
                    f7.dialog.alert(data.msg, function () {
                      f7.views.main.router.navigate({name: 'form1'});
                      f7.popup.close();
                    });
                  }
                }
                else {
                  f7.dialog.alert(data.msg);
                }
              },
              function() {
                app.preloader.hide();
                f7.dialog.alert('Failed to Connect');
              }
              );
            }
          }
        }
      });

      //resend otp
      $$('.resendOTP').on('click', function() {
        f7.methods.request('https://nexcredloan.com/app/php/auth.php',
        {OTPresend: true, phone: localStorage.getItem('phone')},
        'json',
        function() {},
        function(data) {
          if(data.status == 'success') {
            f7.dialog.alert(data.msg);
          }
          else {
            f7.dialog.alert(data.status);
          }
        },
        function() {
          f7.dialog.alert('Failed to Connect');
        }
        );
      });

      //save agreement
      $$('.confirm-signature').on('click', ()=> {
          f7.preloader.show();
          f7.methods.saveAgreement((success) => {
            if(success.status == 'success') {
              f7.preloader.hide();
              f7.views.main.router.navigate({name: 'wallet'});
              f7.popup.close();
            }
          }, (error) => {
            f7.preloader.hide();
            f7.dialog.alert('Something Went Wrong. PLease try again');
          });
      });
    },
  },
});

